











































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import FilterBox from "../filter-box.vue";
@Component({
  components: {
    FilterBox,
  },
})
export default class Name extends mixins(listMixin) {
  @Prop({ type: Boolean, default: false })
  private hideFilter: any;
  private data: any = [];
  private filterChange(e: any) {
    this.filters = JSON.parse(JSON.stringify(e));
  }
  getList() {
    this.data = [
      {
        处方日期: "2024-12-10 10:23:44",
        处方号: "78377",
        处方类型: "中药",
        住院号: "78377",
        门诊号: "78377",
        医嘱名称: "黄芪、桂枝、麻黄 、人参、阿胶",
        科室名称: "肾病科",
      },
    ];
    this.filters.startTime = "";
    this.filters.total_count = 65;
    if (this.data.length > 0) {
      this.ifShowEmpty = false;
    } else {
      this.ifShowEmpty = true;
    }
    // const loading = this.$loading({
    //   lock: true,
    //   text: "加载中……",
    //   spinner: "el-icon-loading",
    //   customClass: "loadClass",
    //   background: "rgba(255, 255, 255, 0.7)",
    // });
    // const params: any = {
    //   params: {
    //     ordering: this.filters.ordering,
    //     page: this.filters.current_page,
    //     limit: 10,
    //   },
    // };
    // if (this.filters.search) {
    //   params.params.name = this.filters.search;
    // }
    // if (this.filters["模板类型"] && this.filters["模板类型"].id) {
    //   params.params.system_type = this.filters["模板类型"].id;
    // }
    // if (this.filters["创建人"] && this.filters["创建人"].length > 0) {
    //   params.params.creator_list = GetIdArr(this.filters["创建人"]).join(",");
    // }
    // localStorage.setItem("sizhenListFilter", JSON.stringify(this.filters));
    // GetTemList(this, params, loading).then((res: any) => {
    //   this.data = res.data;
    //   this.filters.total_count = res.total;
    //   if (this.data.length > 0) {
    //     this.ifShowEmpty = false;
    //   } else {
    //     this.ifShowEmpty = true;
    //   }
    // });
  }
  mounted() {
    this.getList();
  }
}
